import { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";


const Curriculo = () => {
    const Data = useStaticQuery(graphql`
    query {
        file(relativePath: { eq: "files/matheus-tura-resume-pt.pdf" }) {
            publicURL
        }
    }
    `);

    useEffect(() => {
        window.location.replace(`${Data.file.publicURL}`);
    }, []);

    return null;
};

export default Curriculo;
